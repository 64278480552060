import React, { useEffect, useState } from "react";
import axios from "axios";
import { CircularProgress, Snackbar, Alert } from '@mui/material';

const CheckButton = ({ userId, channelId, devUrl, rewardId, setAmount }) => {
    const [buttonClass, setButtonClass] = useState("checkjoinChannelBTN"); 
    const [message, setMessage] = useState('')
    const [newAmount, setNewAmount] = useState(0)
    const [isDisabled, setIsDisabled] = useState(false); 
    const [isLoad, setIsLoad] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false); 
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [btnState, setbtnState] = useState('initial')
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    
    const checkJoin = async (id, chnl) => {
        setIsDisabled(true)
        setbtnState('isload')
        setIsLoad(true)
        const removeUrl = `${devUrl}web/checkchanneljoin`;
        setButtonClass("checkjoinChannelBTNLoading"); 

        axios.post(removeUrl, { userId: userId, channelId: channelId, rewardId: rewardId })
            .then(async(res) => {
                console.log(res.data.message)
                if(res.data.newAmount){
                    setAmount(res.data.newAmount)
                }
                if (res.data.message === 'ok') {
                    setSnackbarMessage('امتیاز دریافت شد');
                    console.log('done')
                    setIsLoad(false)
                    setIsSuccess(true)
                    setbtnState('used')
                    console.log(btnState)
                    setButtonClass("checkjoinChannelBTNSuccess"); 
                } else if(res.data.message === 'notjoin') {
                    setSnackbarMessage('شما هنوز در کانال عضو نشده‌اید');
                    setSnackbarOpen(true); // نمایش Snackbar
                    setIsLoad(false)
                    setButtonClass("checkjoinChannelBTN");
                    setIsLoad(false)
                    setIsDisabled(false)
                } else if(res.data.message === 'getbefore') {
                    setSnackbarMessage('امتیاز امروز را قبلا گرفته‌اید');
                    setSnackbarOpen(true); // نمایش Snackbar
                    setIsLoad(false)
                    setbtnState('used')
                    setButtonClass("checkjoinChannelBTN");
                    setIsLoad(false)
                    setIsDisabled(false)
                }
                
            })
            .catch(err => {
                setButtonClass("checkjoinChannelBTN"); // در صورت وقوع خطا
            });
    };

    useEffect(() => {
        console.log('Button state has changed:', btnState);
    }, [btnState]);

    return (
        <>
        <button 
            className={buttonClass}
            onClick={() => checkJoin(userId, 'someChannel')} 
            disabled={isDisabled}
        >   
            
            { btnState === 'initial' ? (
                <>دریافت</>
            ) : btnState === 'used' ? (
                <>دریافت شد</>
            ) : (
                <>صبر کنید</>
            )}
            {/* { isLoad ? (
                <>صبر کنید</>
            ) : isSuccess ? (

                <>ثبت شد</>
            ) : (
                <>بررسی</>

            )} */}


        </button>
        <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000} // مدت زمان نمایش Snackbar (6 ثانیه)
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }} style={{fontFamily: 'Rubik'}}>
                    {snackbarMessage} {/* پیام خطا یا هشدار */}
                </Alert>
            </Snackbar>
        </>
    );
};

export default CheckButton;
