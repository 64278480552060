import React, { useEffect, useState } from "react";
import { Box,Typography} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { styled } from "@mui/system";
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import CheckButton from "./CheckJoinChannelButton";

const EarnPage = ({backHome,userId, devUrl, earnTasks, setAmount, isDev}) => {
    // const tg = 'test'
    let tg
    if(isDev){
        tg = 'test'
    }else{

        tg = window.Telegram.WebApp
    }


    const colors = {
        gradientBackground: "#212121", // Dark blue to soft purple
        primaryBox: "#212121", // Dark blue shade for the curved box
        button: "#ffe600", // Purple shade for the button
        buttonHover: "#ffe600", // Darker purple for hover effect
        textPrimary: "#EEEEEE", // White text color
      };
    const Typographies = styled(Typography)({
        fontFamily: "Rubik"
      })
    const ReportBox = styled(Box)({
        backgroundColor: colors.primaryBox,
        fontFamily: "Rubik",
        borderRadius: "20px",
        padding: "10px",
        marginBottom: "20px",
        color: colors.textPrimary,
        textAlign: "center",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
        border: "0.2px #303030 solid"
    });
    
    
    
    return(
        <>
            <ReportBox>
            <Typographies variant="h6" sx={{ marginBottom: "10px", width: '320px' }}>
                  <span style={{ fontWeight: 'bold', color: '#ffe8b9', textShadow: "rgba(255, 247, 0, 0.4) 1px 3px 20px" }}>سکه رایگان دریافت کنید</span>
                  
              </Typographies>
            </ReportBox>
            
            {earnTasks.map((task) => (
                <ReportBox key={task.name} style={{display: 'flex', width: '95%', flexDirection: 'row-reverse'}}>
                    <div style={{display: 'flex', flexDirection: 'column', textAlign: 'right', width: '70%'}}>
                        { task.type === 'channel' ?(
                        <span style={{fontWeight: 'bold', fontSize: '15px'}}>
                                عضویت در کانال {task.name}
                            </span>
                        ) : (
                            <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
                                 مشاهده ویدئو {task.name}
                            </span>
                        )}
                        <span style={{fontSize: '12px', marginTop: '12px', color: 'gold', display: 'flex', flexDirection: 'row-reverse'}}>
                        
                        <span style={{marginTop: '2px'}}>  امتیاز دریافتی: {task.value}</span>
                        { task.completed ? (
                            
                        <span style={{marginTop: '2px', marginRight: '4px', color: 'gray'}}>
                            استفاده شده
                        </span>
                        ) : (

                        <CheckButton userId={userId} devUrl={devUrl} channelId={task.channelId} rewardId={task._id} setAmount={setAmount}></CheckButton>
                        )}
                        {/* <button className="checkjoinChannelBTN" onClick={checkJoin}>بررسی</button>   */}
                        </span>
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap', alignContent: 'center'}}>
                    
                    
                    <button className="joinChannelBTN" onClick={() => tg.openTelegramLink(task.channelUrl)}> عضویت</button>
                        
                    </div>
                </ReportBox>
                
            ))}
            
            <button className="nextBtn" onClick={backHome}>بازگشت به صفحه اصلی</button>
        </>
    )
}

export default EarnPage;